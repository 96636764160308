import { Controller } from "@hotwired/stimulus"
import {put} from '@rails/request.js'

export default class extends Controller {

    async onSelect(e) {
        let id = e.currentTarget.id
        let parts = id.split('_')
        let closed_on_default = document.getElementById('user-area--main-section--right-sidebar--updatable-content--expert-mode-form--toggle-block-wrapper').classList.contains('hidden')
        await put(`/user_area/scenario`, {
            responseKind: "turbo-stream",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                user: {
                    selected_scenario: parts[parts.length - 1]
                },
                closed_on_default: closed_on_default
            })
        })
    }
}
